/* Copyright Levelise Ltd 2019-2024 */
import React, { Component } from 'react';

const FleetConext = React.createContext({
	display: '',
	statusType: '',
	searchTerm: '',
	searchDrus: null,
	selectedTimeFrame: '',
	startDate: null,
	endDate: null,
	resetZoom: false,
	timezone: '',
	selectedTimezone: '',
	faultCodes: {},
	status: {},
	afStatus: {},
	aggFacilities: [],
	aggFacilityContracts: [],
	currentDataType: '',
	currentDataTypeParent: '',
	facilities: [],
	druList: [],
	regionDrus: {},
	currentDrus: [],
	settlementMethodDrus: {},
	batterySystemSpecList: [],
	batterySystems: {},
	meteringTypes: {},
	batterySystemTypes: [],
	batterySystemDrus: {},
	selectedBatterySystemType: null,
	hwSpecList: [],
	hwTanks: {},
	hwTypes: [],
	selectedHwType: null,
	selectedSensor: '',
	dailySumAggFacilities: {},
	weeklyReports: [],
	showStatus: Boolean,
	error: null,
	customers: [],
	customerIds: '',
	customerSearchTerm: '',
	selectedCustomer: null,
	haveSetTimezonePreference: false,
	customerUsers: [],
	customerSelectedInfoTab: 'Info',
	fleetCustomersList: [],

	setDisplay: () => {},
	setStatusType: () => {},
	setSearchTerm: () => {},
	setSearchDrus: () => {},
	setTimezone: () => {},
	setSelectedTimezone: () => {},
	setSelectedTimeFrame: () => {},
	setStartDate: () => {},
	setEndDate: () => {},
	setResetZoom: () => {},
	setFaultCodes: () => {},
	setStatus: () => {},
	setAfStatus: () => {},
	setShowStatus: () => {},
	setDruList: () => {},
	setDailySumAggFacilities: () => {},
	setAggregatedFacilities: () => {},
	setAggFacilityContracts: () => {},
	setCurrentDataType: () => {},
	setCurrentDataTypeParent: () => {},
	setFacilities: () => {},
	setWeeklyReports: () => {},
	setRegionDrus: () => {},
	setCurrentDrus: () => {},
	setSettlementMethodDrus: () => {},
	setBatterySystemSpecsList: () => {},
	setBatterySystems: () => {},
	setMeteringTypes: () => {},
	setBatterySystemTypes: () => {},
	setBatterySystemDrus: () => {},
	setSelectedBatterySystemType: () => {},
	setHwSpecsList: () => {},
	setHwTanks: () => {},
	setHwTypes: () => {},
	setSelectedHwType: () => {},
	setSelectedSensor: () => {},
	setError: () => {},
	clearError: () => {},
	clearState: () => {},
	setCustomers: () => {},
	clearCustomers: () => {},
	setCustomerIds: () => {},
	setCustomerSearchTerm: () => {},
	clearCustomerIds: () => {},
	clearCustomerSearchTerm: () => {},
	setHaveSetTimezonePreference: () => {},
	setSelectedCustomer: () => {},
	setCustomerUsers: () => {},
	setCustomerSelectedInfoTab: () => {},
	setFleetCustomersList: () => {},

});

export default FleetConext;

export class FleetProvider extends Component {
	state = {
		display: '',
		statusType: '',
		searchTerm: '',
		searchDrus: null,
		selectedTimeFrame: '',
		startDate: null,
		endDate: null,
		resetZoom: false,
		timezone: '',
		selectedTimezone: '',
		faultCodes: {},
		status: {},
		afStatus: {},
		aggFacilities: [],
		aggFacilityContracts: [],
		currentDataType: '',
		currentDataTypeParent: '',
		facilities: [],
		druList: [],
		regionDrus: {},
		settlementMethodDrus: {},
		currentDrus: [],
		batterySystemSpecList: [],
		batterySystems: {},
		meteringTypes: {},
		batterySystemTypes: [],
		batterySystemDrus: {},
		selectedBatterySystemType: null,
		hwSpecList: [],
		hwTanks: {},
		hwTypes: [],
		selectedHwType: null,
		selectedSensor: '',
		dailySumAggFacilities: {
			Load: 0,
			PV: 0,
			'Battery charging': 0,
			'Battery discharging': 0,
			'Grid in': 0,
			'Grid out': 0,
		},
		weeklyReports: [],
		showStatus: true,
		error: null,
		customers: [],
		customerIds: '',
		customerSearchTerm: '',
		haveSetTimezonePreference: false,
		selectedCustomer: null,
		customerUsers: [],
		customerSelectedInfoTab: 'Info',
		fleetCustomersList: [],

	};

	setDisplay = (display) => this.setState({ display });
	setStatusType = (statusType) => this.setState({ statusType });
	setSearchTerm = (searchTerm) => this.setState({ searchTerm });
	setSearchDrus = (searchDrus) => this.setState({ searchDrus });
	setSelectedTimeFrame = (selectedTimeFrame) => this.setState({ selectedTimeFrame });
	setStartDate = (startDate) => this.setState({ startDate });
	setEndDate = (endDate) => this.setState({ endDate });
	setResetZoom = () => this.setState({ resetZoom: !this.state.resetZoom });
	setTimezone = (timezone) => this.setState({ timezone });
	setSelectedTimezone = (selectedTimezone) => this.setState({ selectedTimezone });
	setFaultCodes = (faultCodes) => this.setState({ faultCodes });
	setStatus = (status) => this.setState({ status });
	setAfStatus = (afStatus) => this.setState({ afStatus });
	setShowStatus = (show) => this.setState({ showStatus: show });
	setDruList = (druList) => this.setState({ druList });
	setAggregatedFacilities = (aggFacilities) => this.setState({ aggFacilities });
	setAggFacilityContracts = (aggFacilityContracts) => this.setState({ aggFacilityContracts });
	setFacilities = (facilities) => {
		this.setState({
			facilities,
			display: 'facility',
			selectedBatterySystemType: '',
			batterySystemTypes: [],
			searchTerm: '',
			searchDrus: null,
		});
	};
	setWeeklyReports = (reports) => this.setState({ weeklyReports: [...reports] });
	setCurrentDataType = (currentDataType) => this.setState({ currentDataType });
	setCurrentDataTypeParent = (currentDataTypeParent) => this.setState({ currentDataTypeParent });
	setRegionDrus = (regionDrus) => this.setState({ regionDrus });
	setCurrentDrus = (currentDrus) => this.setState({ currentDrus });
	setSettlementMethodDrus = (settlementMethodDrus) => this.setState({ settlementMethodDrus });
	setBatterySystemSpecsList = (batterySystemSpecList) => this.setState({ batterySystemSpecList });
	setBatterySystems = (batterySystems) => this.setState({ batterySystems });
	setMeteringTypes = (meteringTypes) => this.setState({ meteringTypes });
	setBatterySystemTypes = (batterySystemTypes) => this.setState({ batterySystemTypes });
	setBatterySystemDrus = (batterySystemDrus) => this.setState({ batterySystemDrus });
	setSelectedBatterySystemType = (selectedBatterySystemType) => this.setState({ selectedBatterySystemType });
	setHwSpecsList = (hwSpecList) => this.setState({ hwSpecList });
	setHwTanks = (hwTanks) => this.setState({ hwTanks });
	setHwTypes = (hwTypes) => this.setState({ hwTypes });
	setSelectedHwType = (selectedHwType) => this.setState({ selectedHwType });
	setSelectedSensor = (selectedSensor) => this.setState({ selectedSensor });

	setDailySumAggFacilities = (type, value) => {
		let dailySums = { ...this.state.dailySumAggFacilities };
		dailySums[type] = dailySums[type] + value;
		this.setState({ dailySumAggFacilities: dailySums });
	};

	setCustomers = (customers) => this.setState({ customers });

	clearCustomers = () => this.setState({ customers: [] });

	setCustomerIds = (customerIds) => this.setState({ customerIds });
	clearCustomerIds = () => this.setState({ customerIds: '' });

	setCustomerSearchTerm = (customerSearchTerm) => this.setState({ customerSearchTerm });
	clearCustomerSearchTerm = () => this.setState({ customerSearchTerm: '' });

	setHaveSetTimezonePreference = (bool) => this.setState({ haveSetTimezonePreference: bool });

	setSelectedCustomer = (customer) => this.setState({ selectedCustomer: customer });

	setCustomerUsers = (customerUsers) => this.setState({ customerUsers });

	setCustomerSelectedInfoTab = (tab) => this.setState({ customerSelectedInfoTab: tab });
	setFleetCustomersList = (arr) => this.setState({ fleetCustomersList: arr });

	clearState = () => {
		this.setState({
			display: '',
			statusType: '',
			searchTerm: '',
			searchDrus: null,
			selectedTimeFrame: '',
			startDate: null,
			endDate: null,
			resetZoom: false,
			timezone: '',
			selectedTimezone: '',
			faultCodes: {},
			status: {},
			afStatus: {},
			aggFacilities: [],
			aggFacilityContracts: [],
			currentDataType: '',
			currentDataTypeParent: '',
			facilities: [],
			druList: [],
			regionDrus: {},
			settlementMethodDrus: {},
			currentDrus: [],
			batterySystemSpecList: [],
			batterySystems: {},
			meteringTypes: {},
			batterySystemTypes: [],
			batterySystemDrus: {},
			selectedBatterySystemType: null,
			hwSpecList: [],
			hwTanks: {},
			hwTypes: [],
			selectedHwType: null,
			dailySumAggFacilities: {
				Load: 0,
				PV: 0,
				'Battery charging': 0,
				'Battery discharging': 0,
				'Grid in': 0,
				'Grid out': 0,
			},
			weeklyReports: [],
			showStatus: true,
			error: null,
			customerUsers: [],
			selectedCustomer: null,
			customerSelectedInfoTab: 'Info',
			fleetCustomersList: [],

		});
	};

	setError = (error) => {
		if (!this.state.error) this.setState({ error });
	};

	clearError = () => this.setState({ error: null });

	render() {
		const contextValue = {
			display: this.state.display,
			statusType: this.state.statusType,
			searchTerm: this.state.searchTerm,
			searchDrus: this.state.searchDrus,
			selectedTimeFrame: this.state.selectedTimeFrame,
			startDate: this.state.startDate,
			endDate: this.state.endDate,
			resetZoom: this.state.resetZoom,
			timezone: this.state.timezone,
			selectedTimezone: this.state.selectedTimezone,
			faultCodes: this.state.faultCodes,
			status: this.state.status,
			afStatus: this.state.afStatus,
			aggFacilities: this.state.aggFacilities,
			aggFacilityContracts: this.state.aggFacilityContracts,
			currentDataType: this.state.currentDataType,
			currentDataTypeParent: this.state.currentDataTypeParent,
			facilities: this.state.facilities,
			druList: this.state.druList,
			regionDrus: this.state.regionDrus,
			currentDrus: this.state.currentDrus,
			settlementMethodDrus: this.state.settlementMethodDrus,
			batterySystemSpecList: this.state.batterySystemSpecList,
			batterySystems: this.state.batterySystems,
			meteringTypes: this.state.meteringTypes,
			batterySystemTypes: this.state.batterySystemTypes,
			batterySystemDrus: this.state.batterySystemDrus,
			selectedBatterySystemType: this.state.selectedBatterySystemType,
			hwSpecList: this.state.hwSpecList,
			hwTanks: this.state.hwTanks,
			hwTypes: this.state.hwTypes,
			selectedHwType: this.state.selectedHwType,
			selectedSensor: this.state.selectedSensor,
			dailySumAggFacilities: this.state.dailySumAggFacilities,
			weeklyReports: this.state.weeklyReports,
			showStatus: this.state.showStatus,
			error: this.state.error,
			customers: this.state.customers,
			customerIds: this.state.customerIds,
			customerSearchTerm: this.state.customerSearchTerm,
			haveSetTimezonePreference: this.state.haveSetTimezonePreference,
			selectedCustomer: this.state.selectedCustomer,
			customerUsers: this.state.customerUsers,
			customerSelectedInfoTab: this.state.customerSelectedInfoTab,
			fleetCustomersList: this.state.fleetCustomersList,

			setDisplay: this.setDisplay,
			setStatusType: this.setStatusType,
			setSearchTerm: this.setSearchTerm,
			setSearchDrus: this.setSearchDrus,
			setTimezone: this.setTimezone,
			setSelectedTimezone: this.setSelectedTimezone,
			setSelectedTimeFrame: this.setSelectedTimeFrame,
			setStartDate: this.setStartDate,
			setEndDate: this.setEndDate,
			setResetZoom: this.setResetZoom,
			setFaultCodes: this.setFaultCodes,
			setStatus: this.setStatus,
			setAfStatus: this.setAfStatus,
			setShowStatus: this.setShowStatus,
			setDruList: this.setDruList,
			setAggregatedFacilities: this.setAggregatedFacilities,
			setCurrentDataType: this.setCurrentDataType,
			setCurrentDataTypeParent: this.setCurrentDataTypeParent,
			setFacilities: this.setFacilities,
			setAggFacilityContracts: this.setAggFacilityContracts,
			setDailySumAggFacilities: this.setDailySumAggFacilities,
			setWeeklyReports: this.setWeeklyReports,
			setRegionDrus: this.setRegionDrus,
			setCurrentDrus: this.setCurrentDrus,
			setSettlementMethodDrus: this.setSettlementMethodDrus,
			setBatterySystemSpecsList: this.setBatterySystemSpecsList,
			setBatterySystems: this.setBatterySystems,
			setMeteringTypes: this.setMeteringTypes,
			setBatterySystemTypes: this.setBatterySystemTypes,
			setBatterySystemDrus: this.setBatterySystemDrus,
			setSelectedBatterySystemType: this.setSelectedBatterySystemType,
			setHwSpecsList: this.setHwSpecsList,
			setHwTanks: this.setHwTanks,
			setHwTypes: this.setHwTypes,
			setSelectedHwType: this.setSelectedHwType,
			setSelectedSensor: this.setSelectedSensor,
			clearState: this.clearState,
			setError: this.setError,
			clearError: this.clearError,
			setCustomers: this.setCustomers,
			clearCustomers: this.clearCustomers,
			setCustomerIds: this.setCustomerIds,
			clearCustomerIds: this.clearCustomerIds,
			setCustomerSearchTerm: this.setCustomerSearchTerm,
			clearCustomerSearchTerm: this.clearCustomerSearchTerm,
			setHaveSetTimezonePreference: this.setHaveSetTimezonePreference,
			setSelectedCustomer: this.setSelectedCustomer,
			setCustomerUsers: this.setCustomerUsers,
			setCustomerSelectedInfoTab: this.setCustomerSelectedInfoTab,
			setFleetCustomersList: this.setFleetCustomersList,

		};
		return <FleetConext.Provider value={contextValue}>{this.props.children}</FleetConext.Provider>;
	}
}
