/* Copyright Levelise Ltd 2024 */
import React, { useState, useRef } from 'react';
import '../CustomerList/index.css';
import './index.css';
import FleetContext from '../../contexts/FleetContext';
import FleetService from '../../services/fleet-service';
import UserService from '../../services/user-service';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { Button, SpecInput } from '../Form';
import { useMemo } from 'react';
import { useCallback } from 'react';
import TabContainer from '../TabContainer';
import AddBtn from '../AddBtn';

const FleetCustomerList = () => {
	const navigate = useNavigate();

	const fleetContext = useContext(FleetContext);

	const [customerIds, setCustomerIds] = useState('');
	const [customerSearchTerm, setCustomerSearchTerm] = useState('');
	const [loading, setLoading] = useState(false);

	const ref = useRef(0);

	const setCustomers = (arr) => fleetContext.setFleetCustomersList(arr);

	const onCustomerClicked = useCallback(
		(customer) => {
			fleetContext.setCustomerSelectedInfoTab('Info');
			navigate('/customer', { state: { customer } });
		},
		[navigate]
	);

	const getCustomersList = useCallback(() => {
		if (fleetContext && fleetContext?.fleetCustomersList && fleetContext?.fleetCustomersList?.length > 0) {
			return fleetContext.fleetCustomersList?.map((customer, index) => (
				<li
					key={index}
					style={{
						cursor: 'pointer',
					}}
				>
					<span className="strong customer" onClick={() => onCustomerClicked(customer)}>
						{customer?.name} {customer?.surname}
					</span>
					<span className="subtitle customer" onClick={() => onCustomerClicked(customer)}>
						{customer?.address1}
					</span>
					<span className="subtitle customer" onClick={() => onCustomerClicked(customer)}>
						{customer?.city}
					</span>
				</li>
			));
		}
	}, [onCustomerClicked, fleetContext]);

	const customersList = useMemo(() => getCustomersList(), [getCustomersList]);

	const onSearchClicked = async () => {
		setLoading(true);
		try {
			const res = await FleetService.getUserCustomers(UserService.getUsername(), customerIds, customerSearchTerm);

			if (res && Object.hasOwn(res, 'customers') && res.customers?.length > 0) {
				setCustomers(res.customers);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};

	const onClearClicked = () => {
		setCustomers([]);
		setCustomerIds('');
		setCustomerSearchTerm('');
	};

	const onIdsChange = (e) => {
		setCustomerIds(e.target.value);
	};

	const onSearchTermChange = (e) => {
		setCustomerSearchTerm(e.target.value);
	};

	const onAddCustomerClick = () => {
		navigate('/add-customer');
	};

	return (
		<TabContainer title="Customers" rightCornerComponent={<AddBtn onAddClick={onAddCustomerClick} />}>
			<div className="search-inputs-container">
				<div className="label-input-container">
					<label htmlFor="idInput">ID</label>
					<SpecInput
						id="customer-id-input"
						name="idInput"
						type="text"
						placeholder="Customer ID e.g. 12"
						value={customerIds}
						onChange={onIdsChange}
					/>
				</div>
				<div className="label-input-container">
					<label htmlFor="searchInput">Search</label>
					<SpecInput
						id="customer-info-input"
						name="searchInput"
						type="text"
						placeholder="Name/Address/Email/Phone"
						value={customerSearchTerm}
						onChange={onSearchTermChange}
					/>
				</div>
				<div className="customer-search-button-container">
					<Button className="configure-btn reset" onClick={onClearClicked}>
						Clear
					</Button>
					<Button
						className={'configure-btn submit'}
						disabled={loading || !(customerIds || customerSearchTerm)}
						onClick={onSearchClicked}
					>
						Search
					</Button>
				</div>
			</div>
			<ul ref={ref} className={'fleet-customer-list'} data-cy="fleet-customer-search-results">
				{customersList}
				{fleetContext?.fleetCustomersList?.length ? (
					<li
						key={'lastCustomer'}
						style={{
							cursor: 'pointer',
						}}
					>
						<span className="strong customer"></span>
					</li>
				) : null}
			</ul>
		</TabContainer>
	);
};

export default FleetCustomerList;
